import React from "react";
import CustomDropZone from "./CustomDropZone";
import axios from "axios";
import { awsUrl2, awsUrl } from "../../config";
import LoadingOverlay from "react-loading-overlay";

const headingMap = {
  first: "Attach Loss Run",
  second: "Other Documents",
};

const folderMap = {
  first: "LossRun",
  second: "OtherDocuments",
};

export default class DropZones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: this.props.fileList,
      uploadingInProgress: false,
      uploadMsg: undefined,
      errMsg: undefined,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.fileList) !== JSON.stringify(prevProps.fileList)
    ) {
      this.setState({ fileList: this.props.fileList });
    }
  }

  updateFileList = (name, newFile) => {
    let { fileList } = this.state;
    if (fileList[name][newFile.name] === "#empty") {
      this.setState({ errMsg: "File already exist", uploadMsg: undefined });
      return;
    }
    fileList[name][newFile.name] = newFile;
    this.setState({ fileList, errMsg: undefined, uploadMsg: undefined });
  };

  removeFile = (name, fileName) => {
    let { fileList } = this.state;
    delete fileList[name][fileName];
    this.setState({ fileList });
  };

  uploadFile = (fileData, postDataS3) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrl + "/api/uploadLossForm", postDataS3)
        .then((response) => {
          let returnData = response.data;
          let lossSignedURL = returnData.lossSignedURL;
          let options = { header: { "Content-Type": postDataS3.fileType } };
          axios
            .put(lossSignedURL, fileData, options)
            .then((result) => {
              console.log("file upload res: ", result);
              resolve();
            })
            .catch((error) => {
              console.log("error in uploading", error);
              reject();
            });
        })
        .catch((error) => {
          console.log("error in uploadLossForm ", error);
          reject();
        });
    });
  };

  updateWcnUserStatusTable = (dataToSend) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrl2 + "/api/updateWcnUserStatusTable", dataToSend)
        .then((res) => {
          resolve();
        })
        .catch((error) => {
          console.log("error in API-updateWcnUserStatusTable", error);
          reject();
        });
    });
  };

  handleUpload = () => {
    let { updatePopUpData } = this.props;
    updatePopUpData({
      show: true,
      title: "Do you wish to conitnue?",
      children: (
        <>
          <br></br>
          <span style={{ fontWeight: "bold" }}>
            Files once uploaded cannot be deleted
          </span>
        </>
      ),
      acceptBtn: "Yes",
      acceptBtnCallback: async () => {
        try {
          let { fileList } = this.state;
          this.setState({ uploadingInProgress: true });
          let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
          let quoteData = JSON.parse(sessionStorage.getItem("quoteData"));
          const email = currProspect.companyProfile.emailId.value;
          const folder1 = `${email}/${quoteData.uuid}_${quoteData.date}`;
          let sortKeyList = JSON.parse(sessionStorage.getItem("sortKeyList"));
          let dataToSendPromise = [];

          let promiseList = [],
            fileListToStore = [];

          for (let key in fileList) {
            if (fileList[key]) {
              for (let fileName in fileList[key]) {
                let fileType = fileName.split(".").reverse()[0];
                const folder2 = folderMap[key];
                fileListToStore.push({
                  key: `${folder1}/${folder2}/${fileName}`,
                  fileType,
                  keyToFile: key,
                });

                const postDataS3 = {
                  email,
                  folder1,
                  folder2,
                  fileName,
                  fileType,
                };
                if (fileList[key][fileName] !== "#empty")
                  promiseList.push(
                    this.uploadFile(fileList[key][fileName], postDataS3)
                  );
              }
            }
          }

          Promise.all(promiseList)
            .then((res) => {
              for (let sortKey in sortKeyList) {
                dataToSendPromise.push(
                  this.updateWcnUserStatusTable({
                    user_email_id: email,
                    uuid_carrier: sortKeyList[sortKey],
                    fileListToStore,
                    doc_status: "uploaded",
                  })
                );
              }

              Promise.all(dataToSendPromise)
                .then((res) => {
                  this.setState({
                    uploadingInProgress: false,
                    uploadMsg: "files uploaded",
                    errMsg: undefined,
                  });
                })
                .catch((error) => console.log("error in API", error));
            })
            .catch((error) => {
              console.log("error: ", error);
              this.setState({
                uploadingInProgress: false,
                errMsg: "Error",
                uploadMsg: undefined,
              });
            });
        } catch (error) {
          console.log("error: ", error);
        }
      },
    });
  };

  startNewQuote = () => {
    let otpVerified = sessionStorage.getItem("otpVerified");
    let isLoggedIn = sessionStorage.getItem("isLoggedIn");
    sessionStorage.clear();
    sessionStorage.setItem("isLoggedIn", isLoggedIn);
    sessionStorage.setItem("otpVerified", otpVerified);
    window.location.reload();
  };

  render() {
    let { fileList, uploadingInProgress, uploadMsg, errMsg } = this.state;
    return (
      <LoadingOverlay active={uploadingInProgress} spinner>
        <div className="dropZonesContainer">
          <div className="row mb-3">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <CustomDropZone
                name="first"
                fileList={fileList}
                updateFileList={this.updateFileList}
                removeFile={this.removeFile}
                headingMap={headingMap}
                uploadMsg={uploadMsg}
              />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <CustomDropZone
                name="second"
                fileList={fileList}
                updateFileList={this.updateFileList}
                removeFile={this.removeFile}
                headingMap={headingMap}
                uploadMsg={uploadMsg}
              />
            </div>
          </div>
          <div className="d-flex flex-column">
            <pre
              className={
                "text-center text-capitalize d-flex flex-column " +
                (uploadMsg ? "text-success" : "text-danger")
              }
            >
              {uploadMsg && (
                <>
                  <span>
                    Thank You!! Please expect your proposal momentarily.
                  </span>
                  <span
                    onClick={this.startNewQuote}
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    Click to start a new submission.
                  </span>
                </>
              )}
            </pre>
            <button
              className="btn btn-warning btn-sm btn-warning-shadow upload-docs"
              style={{ width: "min-content", alignSelf: "center" }}
              onClick={this.handleUpload}
              disabled={uploadingInProgress}
            >
              Upload
            </button>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}
