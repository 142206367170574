import React, { Component } from "react";

class StatsBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="stats-banner d-flex align-items-center text-center">
        <div className="stats-banner-item-1 d-flex justify-content-center">
          <span>47 States</span>
        </div>
        <div className="stats-banner-item-2 d-flex justify-content-center">
          <span>
            117 Years of <br></br>Combined Leadership
          </span>
        </div>
        <div className="stats-banner-item-3 d-flex justify-content-center">
          <span>97% Retention</span>
        </div>
      </div>
    );
  }
}

export default StatsBanner;
