import React, { Component } from "react";
import { Link } from "react-router-dom";
let workCompNowLogo = require("../../images/workcompnow-logo.jpg");
let insurecompLogo = require("../../images/new-logo.png");

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="header">
        <div className="logos-container">
          <Link to="/">
            {process.env.REACT_APP_MODE !== "beta" && (
              <div className="wcn-logo-container">
                <img src={workCompNowLogo} alt="solve-insurecomp logo" />
              </div>
            )}
          </Link>
          <Link to="/">
            <div className="wcn-logo-container">
              <img src={insurecompLogo} alt="affinity-tech logo" />
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export default Header;
