import React, { Component, forwardRef } from "react";
import axios from "axios";
import { awsUrl2, awsUrl } from "../../config";
import Clamp from "react-multiline-clamp";
import moment from "moment";
import numeral from "numeral";
import MaterialTable from "material-table";
import { TableBody, TablePagination } from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Check from "@material-ui/icons/Check";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
};

class DashboardAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount = () => {
    if (this.props.tabName === "tab5") {
      this.updateDirectUsersDisplay();
    } else if (this.props.tabName === "tab4") {
      this.updateRegisteredUsersDisplay();
    } else if (this.props.tabName === "tab3") {
      this.updateAgentDisplay();
    } else if (this.props.tabName === "tab_later") {
      this.updateEligibilityDisplay();
    } else {
      this.updateDisplay();
    }
  };

  updateComment = (e, rowData) => {
    let { rowDataList } = JSON.parse(JSON.stringify(this.state));
    let index = rowData.tableData.id;
    let requestBody = {};
    requestBody.email = rowDataList[index].userEmail;
    requestBody.comment = e.target.value;
    axios
      .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
      .then((res) => {})
      .catch((error) => {
        console.log("error in posting data to user tracking", error);
      });
  };

  updateDiscountData = (rowData) => {
    let requestBody = {
      user_email_id: rowData.userEmail,
      uuid_carrier: rowData.uuid_carrier,
      discountData: rowData.discountData,
    };
    axios
      .post(awsUrl2 + "/api/updateSolveUserStatusTable", requestBody)
      .then((res) => {})
      .catch((err) => console.log("error: ", err));
  };

  camelCase = (str) => {
    return str
      .trim()
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  };

  formatFein = (value) => {
    let fein;
    value = value.split("-").join("");
    var len = value.length,
      dummystr = "000000000";

    if (len === 0) {
      fein = "-";
    } else if (len > 0 && len < 9) {
      value = dummystr.slice(0, 9 - len) + "" + value;
      fein = value[0] + value[1] + "-" + value.slice(2);
    } else {
      fein = value[0] + value[1] + "-" + value.slice(2);
    }

    return fein;
  };

  setTableData = () => {
    let { rowDataList, isLoading, discountDataObject } = JSON.parse(
      JSON.stringify(this.state)
    );
    let { tabName } = this.props;

    let columns = {
      tab1: [
        {
          title: "Visit Date (ET)",
          field: "visitDate",
          defaultSort: "desc",
          render: (rowData) => {
            if (rowData.visitDate) {
              let visitDate = moment(Number(rowData.visitDate))
                .utcOffset("-0500")
                .format("M/D/YYYY - hh:mm A");
              let date = visitDate.split("-")[0];
              let time = visitDate.split("-")[1];
              return (
                <span>
                  {date}
                  <br></br>
                  {time}
                </span>
              );
            } else {
              return <span>""</span>;
            }
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
          },
        },
        {
          title: "Company Name",
          field: "companyName",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "110px",
            width: "110px",
            maxWidth: "110px",
            wordBreak: "break-word",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              {rowData.companyName ? this.camelCase(rowData.companyName) : ""}
            </Clamp>
          ),
        },
        {
          title: "Contact Name",
          field: "contactName",
          cellStyle: {
            padding: "6px 15px 6px 10px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              {rowData.contactName ? this.camelCase(rowData.contactName) : ""}
            </Clamp>
          ),
        },
        {
          title: "Phone Number",
          field: "phoneNumber",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "110px",
            width: "110px",
            maxWidth: "110px",
          },
          render: (rowData) => (
            <>{rowData.phoneNumber ? rowData.phoneNumber : ""}</>
          ),
        },
        {
          title: "Email",
          field: "userEmail",
          cellStyle: {
            wordBreak: "break-all",
            padding: "6px 15px 6px 0px",
            minWidth: "160px",
            width: "160px",
            maxWidth: "160px",
          },
          headerStyle: {
            padding: "6px 15px 6px 0px",
          },
          render: (rowData) => (
            <Clamp lines={1}>
              {rowData.userEmail ? rowData.userEmail.toLowerCase() : ""}
            </Clamp>
          ),
        },
        {
          title: "Signed Up",
          field: "signedUp",
          cellStyle: {
            padding: "6px 15px 6px 10px",
          },
          render: (rowData) => (rowData.signedUp ? rowData.signedUp : ""),
        },
        {
          title: "Campaign Sent",
          field: "campaignSentDate",
          cellStyle: {
            padding: "6px 15px 6px 10px",
          },
          render: (rowData) =>
            rowData.campaignSentDate
              ? moment(rowData.campaignSentDate, "YYYY-MM-DD").format(
                  "M/D/YYYY"
                )
              : "",
        },
        {
          title: "Comments",
          field: "comment",
          render: (rowData) => (
            <textarea
              defaultValue={rowData.comment || ""}
              onBlur={(e) => this.updateComment(e, rowData)}
              rows="2"
              style={{ width: "100%" }}
            ></textarea>
          ),
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "250px",
            width: "250px",
            maxWidth: "250px",
          },
        },
      ],
      tab2: [
        {
          title: "Company Name",
          field: "companyName",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "140px",
            width: "140px",
            maxidth: "140px",
          },
          render: (rowData) => (
            <Clamp lines={2}>{this.camelCase(rowData.companyName)}</Clamp>
          ),
        },
        {
          title: "Contact Name",
          field: "contactName",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "130px",
            width: "130px",
            maxWidth: "130px",
          },
          render: (rowData) => (
            <Clamp lines={2}>{this.camelCase(rowData.contactName)}</Clamp>
          ),
        },
        {
          title: "Phone Number",
          field: "phoneNumber",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "130px",
            width: "130px",
            maxWidth: "130px",
          },
        },
        {
          title: "Email",
          field: "userEmail",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            wordBreak: "break-all",
            minWidth: "180px",
            width: "180px",
            maxWidth: "180px",
          },
          render: (rowData) => (
            <Clamp lines={1}>{rowData.userEmail.toLowerCase()}</Clamp>
          ),
        },
        {
          title: "Campaign Sent",
          field: "campaignSentDate",
          cellStyle: {
            padding: "6px 15px 6px 10px",
          },
          render: (rowData) =>
            rowData.campaignSentDate
              ? moment(rowData.campaignSentDate, "YYYY-MM-DD").format(
                  "M/D/YYYY"
                )
              : "",
        },
        {
          title: "Comments",
          field: "comment",
          render: (rowData) => (
            <textarea
              defaultValue={rowData.comment || ""}
              onBlur={(e) => this.updateComment(e, rowData)}
              rows="2"
              style={{ width: "100%" }}
            ></textarea>
          ),
          cellStyle: {
            minWidth: "300px",
            width: "300px",
            maxWidth: "300px",
            padding: "6px 15px 6px 10px",
          },
        },
      ],
      tab3: [
        {
          title: "Agent",
          field: "agent_email",
          cellStyle: {
            wordBreak: "break-all",
            padding: "6px 15px 6px 10px",
            minWidth: "160px",
            width: "160px",
            maxWidth: "160px",
          },
          headerStyle: {
            padding: "6px 15px 6px 10px",
          },
          render: (rowData) => (
            <Clamp lines={1}>{rowData.agent_email.toLowerCase()}</Clamp>
          ),
        },
        {
          title: "Visit Date (ET)",
          field: "visitDate",
          defaultSort: "desc",
          render: (rowData) => {
            let visitDate = moment(Number(rowData.visitDate))
              .utcOffset("-0500")
              .format("M/D/YYYY - hh:mm A");
            let date = visitDate.split("-")[0];
            let time = visitDate.split("-")[1];
            return (
              <span>
                {date}
                <br></br>
                {time}
              </span>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
          },
        },
        {
          title: "Company Name",
          field: "companyName",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "110px",
            width: "110px",
            maxWidth: "110px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              <span>
                <div>
                  {/* <Link
                    to={"/Quote" + "?" + "sales=" + rowData.userEmail}
                    target="_blank"
                  > */}
                  {this.camelCase(rowData.companyName)}
                  {/* </Link> */}
                </div>
              </span>
            </Clamp>
          ),
        },
        {
          title: "Contact Name",
          field: "contactName",
          cellStyle: {
            padding: "6px 15px 6px 10px",
          },
          render: (rowData) => (
            <Clamp lines={2}>{this.camelCase(rowData.contactName)}</Clamp>
          ),
        },
        {
          title: "Phone Number",
          field: "phoneNumber",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "110px",
            width: "110px",
            maxWidth: "110px",
          },
        },
        {
          title: "Total Payroll",
          field: "indicationCost",
          render: (rowData) => numeral(rowData.indicationCost).format("$0,0"),
          cellStyle: {
            padding: "6px 0",
            wordBreak: "break-all",
          },
          headerStyle: {
            padding: "6px 0",
          },
        },
        {
          title: "Effective Date",
          field: "effectiveDate",
          render: (rowData) =>
            moment(Number(rowData.effectiveDate)).format("M/D/YYYY"),
          cellStyle: {
            padding: "6px 15px 6px 10px",
          },
        },
        {
          title: "Email",
          field: "userEmail",
          cellStyle: {
            wordBreak: "break-all",
            padding: "6px 15px 6px 0px",
            minWidth: "160px",
            width: "160px",
            maxWidth: "160px",
          },
          headerStyle: {
            padding: "6px 15px 6px 0px",
          },
          render: (rowData) => (
            <Clamp lines={1}>{rowData.userEmail.toLowerCase()}</Clamp>
          ),
        },
        {
          title: "Status",
          field: "step",
          cellStyle: (e, rowData) => {
            let style = { padding: "6px 15px 6px 10px" };

            if (rowData.step === "Rejected") {
              style.backgroundColor = "#fa6d3e";
            } else if (rowData.step === "Approved") {
              style.backgroundColor = "#87FA4D";
            } else if (rowData.step === "Docs Pending") {
              style.backgroundColor = "#eb6631a7";
            } else if (rowData.step === "Submitted") {
              style.backgroundColor = "#ffd737ac";
            } else {
              style.backgroundColor = "#FFF";
            }

            return style;
          },
        },
      ],
      tab4: [
        {
          title: "Signup Date (ET)",
          field: "timestamp",
          defaultSort: "desc",
          render: (rowData) => {
            if (rowData.timestamp) {
              let timestamp = moment(Number(rowData.timestamp))
                .utcOffset("-0500")
                .format("M/D/YYYY - hh:mm A");
              let date = timestamp.split("-")[0];
              let time = timestamp.split("-")[1];
              return (
                <span>
                  {date}
                  <br></br>
                  {time}
                </span>
              );
            } else {
              return "";
            }
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            width: "170px",
          },
        },
        {
          title: "Company Name",
          field: "company",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            width: "250px",
          },
          render: (rowData) => (
            <Clamp lines={2}>{this.camelCase(rowData.company || "")}</Clamp>
          ),
        },
        {
          title: "Contact Name",
          field: "name",
          cellStyle: {
            wordBreak: "break-all",
            padding: "6px 15px 6px 0px",
            width: "200px",
          },
          headerStyle: {
            padding: "6px 15px 6px 0px",
          },
          render: (rowData) => (
            <Clamp lines={1}>{this.camelCase(rowData.name || "")}</Clamp>
          ),
        },
        {
          title: "Phone number",
          field: "mobile",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            wordBreak: "break-all",
            width: "200px",
          },
          headerStyle: {
            padding: "6px 15px 6px 10px",
          },
        },
        {
          title: "Email",
          field: "email",
          cellStyle: {
            wordBreak: "break-all",
            padding: "6px 15px 6px 10px",
            width: "250px",
          },
          headerStyle: {
            padding: "6px 15px 6px 10px",
          },
          render: (rowData) => (
            <Clamp lines={1}>{rowData.email.toLowerCase()}</Clamp>
          ),
        },
        {
          title: "FEIN",
          field: "fein",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            width: "250px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              {rowData.fein ? this.formatFein(rowData.fein) : ""}
            </Clamp>
          ),
        },
      ],
      tab5: [
        {
          title: "Visit Date (ET)",
          field: "timestamp",
          defaultSort: "desc",
          render: (rowData) => {
            let timestamp = moment(Number(rowData.timestamp))
              .utcOffset("-0500")
              .format("M/D/YYYY - hh:mm A");
            let date = timestamp.split("-")[0];
            let time = timestamp.split("-")[1];
            return (
              <span>
                {date}
                <br></br>
                {time}
              </span>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            width: "170px",
          },
        },
        {
          title: "Company Name",
          field: "company",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            width: "250px",
          },
          render: (rowData) => (
            <Clamp lines={2}>{this.camelCase(rowData.company)}</Clamp>
          ),
        },
        {
          title: "Contact Name",
          field: "name",
          cellStyle: {
            wordBreak: "break-all",
            padding: "6px 15px 6px 0px",
            width: "200px",
          },
          headerStyle: {
            padding: "6px 15px 6px 0px",
          },
          render: (rowData) => (
            <Clamp lines={1}>{this.camelCase(rowData.name)}</Clamp>
          ),
        },
        {
          title: "Phone number",
          field: "mobile",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            wordBreak: "break-all",
            width: "200px",
          },
          headerStyle: {
            padding: "6px 15px 6px 10px",
          },
        },
        {
          title: "Email",
          field: "email",
          cellStyle: {
            wordBreak: "break-all",
            padding: "6px 15px 6px 10px",
            width: "250px",
          },
          headerStyle: {
            padding: "6px 15px 6px 10px",
          },
          render: (rowData) => (
            <Clamp lines={1}>{rowData.email.toLowerCase()}</Clamp>
          ),
        },
        {
          title: "FEIN",
          field: "fein",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            width: "250px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              {rowData.fein ? this.formatFein(rowData.fein) : ""}
            </Clamp>
          ),
        },
      ],
    };

    return (
      <MaterialTable
        isLoading={isLoading}
        icons={tableIcons}
        data={rowDataList}
        columns={columns[tabName]}
        options={{
          paginationPosition: "both",
          padding: "dense",
          showTitle: false,
          thirdSortClick: false,
          pageSize: 25,
          pageSizeOptions: [25, 50, 75, 100],
          headerStyle: {
            fontSize: "12px",
            backgroundColor: "#003764",
            color: "#FFF",
            padding: "6px 15px 6px 10px",
          },
          rowStyle: {
            fontSize: "12px",
          },
          searchFieldStyle: {
            fontSize: "12px",
          },
        }}
        detailPanel={
          tabName === "tab_later"
            ? [
                {
                  tooltip: "Class code info",
                  icon: () => <KeyboardArrowRightIcon />,
                  render: (rowData) => {
                    return (
                      <div style={{ padding: "25px" }}>
                        <TableContainer component={Paper}>
                          <Table size="small">
                            <TableHead
                              style={{
                                background: "#00000066",
                                color: "whitesmoke",
                              }}
                            >
                              <TableRow>
                                <TableCell>Class Code</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>NAICS</TableCell>
                                <TableCell>Payroll</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rowData.excludedList.map((element, indx) => {
                                return (
                                  <TableRow key={indx}>
                                    <TableCell scope="row">
                                      {element.class_code}
                                    </TableCell>
                                    <TableCell scope="row">
                                      {element.description}
                                    </TableCell>
                                    <TableCell scope="row">
                                      {element.naics}
                                    </TableCell>
                                    <TableCell scope="row">
                                      {element.payroll}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    );
                  },
                },
              ]
            : false
        }
        components={{
          Pagination: this.PatchedPagination,
        }}
      />
    );
  };

  PatchedPagination = (props) => {
    const {
      ActionsComponent,
      onChangePage,
      onChangeRowsPerPage,
      ...tablePaginationProps
    } = props;

    return (
      <TablePagination
        {...tablePaginationProps}
        // @ts-expect-error onChangePage was renamed to onPageChange
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        labelRowsPerPage={
          <div style={{ fontSize: 12 }}>{props.labelRowsPerPage}</div>
        }
        labelDisplayedRows={(row) => (
          <div style={{ fontSize: 12 }}>{props.labelDisplayedRows(row)}</div>
        )}
        SelectProps={{
          style: {
            fontSize: 12,
          },
        }}
        ActionsComponent={(subprops) => {
          const { onPageChange, ...actionsComponentProps } = subprops;
          return (
            // @ts-expect-error ActionsComponent is provided by material-table
            <ActionsComponent
              {...actionsComponentProps}
              onChangePage={onPageChange}
            />
          );
        }}
      />
    );
  };

  updateAgentDisplay = async () => {
    axios
      .get(awsUrl2 + "/api/getAgentDashboardDataAdmin")
      .then((res) => {
        let userDataList = [];
        let rowData;
        let rowDataList = [];
        let step;

        userDataList = res.data;

        for (let userData of userDataList) {
          let indicationCost = 0;

          if (userData.quote_status === "reject") {
            step = "Rejected";
          } else if (userData.quote_status === "proceed_uw") {
            step = "Approved";
          } else if (
            userData.doc_status !== "not_uploaded" ||
            userData.nolossGenerated
          ) {
            step = "Submitted";
          } else if (
            userData.doc_status === "not_uploaded" ||
            !userData.doc_status
          ) {
            step = "Docs Pending";
          } else {
            step = "-";
          }

          for (let stateIndex in userData.prospect_addresses) {
            for (let classCodeData of userData.prospect_addresses[stateIndex]
              .classCodes) {
              indicationCost = numeral(indicationCost)
                .add(classCodeData.payroll)
                .value();
            }
          }

          rowData = {
            visitDate: userData.date,
            userEmail: userData.user_email_id,
            uuid: userData.uuid,
            phoneNumber: userData.phone_number,
            indicationCost,
            effectiveDate: moment(userData.effective_date).format("x"),
            contactName: userData.contact_person,
            companyName: userData.company_name,
            domain: userData.domain,
            quote_status: userData.quote_status,
            doc_status: userData.doc_status,
            agent_email: userData.agent_email,
            step,
            uuid_carrier: userData.uuid_carrier,
          };
          rowDataList.push(rowData);
        }

        this.setState({ rowDataList });
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  updateEligibilityDisplay = async () => {
    await axios
      .get(awsUrl2 + "/api/getNonEligibleData")
      .then((res) => {
        let data = res.data;
        this.setState({ rowDataList: data });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  updateRegisteredUsersDisplay = async () => {
    await axios
      .get(awsUrl2 + "/api/getRegisteredUsersData")
      .then((res) => {
        let data = res.data;
        this.setState({ rowDataList: data });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  updateDirectUsersDisplay = async () => {
    await axios
      .get(awsUrl2 + "/api/getDirectUserTrackingData")
      .then((res) => {
        let data = res.data;
        this.setState({ rowDataList: data });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  updateDisplay = () => {
    let { tabName } = this.props;
    let requestBody = {
      tab: tabName,
    };
    axios
      .post(awsUrl2 + "/api/getDashboardTableData", requestBody)
      .then((res) => {
        let rowData;
        let rowDataList = [];

        let userDataList = res.data;

        for (let userData of userDataList) {
          rowData = {
            visitDate: userData.timestamp,
            userEmail: userData.email,
            signedUp: userData.signedUp === "true" ? "Yes" : "No",
            campaignSentDate: userData.campaign_sent_date,
            // quoteGenerated: userData.quoteGenerated,
            // proposalGenerated: userData.proposalGenerated,
            // uuid: userData.uuid,
            phoneNumber: userData.phone_number,
            // payroll: userData.payroll,
            // indicationCost: userData.indicationCost,
            // emod: userData.emod,
            // effectiveDate: moment(userData.effectiveDate).format("x"),
            contactName: userData.primary_contact_name,
            companyName: userData.company,
            comment: userData.comment,
            // domain: userData.domain,
            // visitCount: userData.visitCount,
            // step,
          };
          rowDataList.push(rowData);
        }

        this.setState({ rowDataList });
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    let { selected, tabName } = this.props;
    return (
      <div
        className="Admin-Dashboard"
        style={
          tabName !== selected ? { display: "none", fontSize: "12px" } : {}
        }
      >
        <div>{this.setTableData()}</div>
      </div>
    );
  }
}

export default DashboardAdmin;
