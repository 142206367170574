import { awsUrl, awsUrl2 } from "../config";
import React, { Component } from "react";
import $ from "jquery";
import axios from "axios";
import moment from "moment";
import {
  deleteAllCookies,
  showErrAlert,
  getHeader,
  blobPdfFromBase64String,
} from "./common";
import { Auth } from "aws-amplify";
import { checkMailCampaignUser } from "../utils/check_mail_campaign_user";
const uuidv4 = require("uuid/v4");
const numeral = require("numeral");
var sizeof = require("object-sizeof");
window.jQuery = $;
window.$ = $;
global.jQuery = $;
require("bootstrap");

let salesPersonLoginCheck = () => {
  let promise = new Promise(async (resolve, reject) => {
    try {
      let sessionData = await Auth.currentAuthenticatedUser();
      let groups;
      if (sessionData) {
        groups =
          sessionData.signInUserSession.accessToken.payload["cognito:groups"];
        if (groups.includes(process.env.REACT_APP_AGENT_GROUP)) {
          resolve({
            salespersonName: sessionData.attributes.email,
            salespersonCheck: true,
          });
        } else {
          resolve({
            salespersonName: "",
            salespersonCheck: false,
          });
        }
      } else {
        resolve({
          salespersonName: "",
          salespersonCheck: false,
        });
      }
    } catch (error) {
      resolve({
        salespersonName: "",
        salespersonCheck: false,
      });
    }
  });

  return promise;
};

const goodAverageWageValidation = (childrenLoc) => {
  let ftPt = 0,
    total = 0;
  for (let key in childrenLoc) {
    let classCodesInfo = childrenLoc[key].classCodesInfo;
    for (let indx in classCodesInfo) {
      let classCodesInfoObj = classCodesInfo[indx];
      ftPt +=
        Number(classCodesInfoObj.ft.value) + Number(classCodesInfoObj.pt.value);
      total += Number(
        classCodesInfoObj.payroll.value.replace(/[^0-9.-]+/g, "")
      );
    }
  }
  return ftPt <= total;
};

const lossRatioCalculation = (historicalClaims, historicalClaimsCheck) => {
  let currYear = new Date().getFullYear();
  let sum_inc = 0,
    sum_prm = 0;
  for (let yr = currYear; yr > currYear - 3; yr--) {
    if (historicalClaims[yr] && !historicalClaimsCheck[yr]) {
      sum_inc += Number(historicalClaims[yr].totInc.replace(/[^0-9.-]+/g, ""));
      sum_prm += Number(historicalClaims[yr].annPre.replace(/[^0-9.-]+/g, ""));
    }
  }
  return (
    numeral(sum_inc)
      .divide(sum_prm)
      .value() < 0.4
  );
};

export let submitQues = async (quesList, updatePopUpData) => {
  $("#loader").css("display", "block");
  try {
    let currProspectDetails = JSON.parse(
      sessionStorage.getItem("currProspect")
    );

    const {
      childrenLoc,
      companyProfile,
      emodStatesData,
      historicalClaims,
      historicalClaimsCheck,
    } = currProspectDetails;

    // let childFlag = goodAverageWageValidation(childrenLoc);
    // let lossFlag = lossRatioCalculation(
    //   historicalClaims,
    //   historicalClaimsCheck
    // );

    // console.log("childFlag: ", childFlag);
    // console.log("lossFlag: ", lossFlag);
    console.dir(currProspectDetails, { depth: null });

    let uwQuesData = [];
    let carrierUserData = [];
    let peoType = "peo_basic";
    // for (let question of quesList) {
    //   let remarks =
    //     !question.remarks || question.remarks.trim() === ""
    //       ? "none"
    //       : question.remarks;
    //   let dataQues = {
    //     resp: question.response ? "Y" : "N",
    //     remarks: remarks,
    //   };
    //   uwQuesData.push(dataQues);
    // }

    // let questions = [];

    currProspectDetails.uwQues = uwQuesData;
    sessionStorage.setItem("currProspect", JSON.stringify(currProspectDetails));

    if (
      childrenLoc &&
      companyProfile &&
      emodStatesData &&
      historicalClaims &&
      historicalClaimsCheck
    ) {
      if (companyProfile.healthQues.value) {
        peoType = "peo_plus";
      }

      let historicalClaimsList = [];
      for (let year in historicalClaims) {
        if (!historicalClaimsCheck[year]) {
          let objRow = {
            year: year,
            carrier: historicalClaims[year].carrier,
            annPre: historicalClaims[year].annPre,
            claim: historicalClaims[year].claim,
            totInc: historicalClaims[year].totInc,
            totPaid: historicalClaims[year].totPaid,
            totRes: historicalClaims[year].totRes,
          };
          historicalClaimsList.unshift(objRow);
        }
      }

      let lossFrequency;
      if (historicalClaimsList.length > 0) {
        let aggClaim = 0;
        let aggPremium = 0;
        for (let historicalClaim of historicalClaimsList) {
          let claim = numeral(historicalClaim.claim).value();
          let premium = numeral(historicalClaim.annPre).value();

          aggClaim = numeral(aggClaim)
            .add(claim)
            .value();
          aggPremium = numeral(aggPremium)
            .add(premium)
            .value();
        }

        if (aggPremium !== 0) {
          lossFrequency = numeral(aggClaim)
            .divide(
              numeral(aggPremium)
                .divide(10000)
                .value()
            )
            .value();
        }
      }

      let { salespersonName, salespersonCheck } = await salesPersonLoginCheck();

      // let dataBody = {
      //   common: {
      //     el_limits: "100-100-500",
      //     deductible: 2000,
      //     date_from: moment(companyProfile.effectiveDate.value).format(
      //       "YYYY-MM-DD"
      //     ),
      //     date_to: moment(companyProfile.effectiveDate.value)
      //       .add(1, "y")
      //       .format("YYYY-MM-DD"),
      //     emod: [],
      //     sr_carriers: {},
      //     peo_type: peoType,
      //   },
      //   offices: [],
      // };

      // if (lossFrequency !== undefined) {
      //   dataBody.common.loss_frequency = lossFrequency;
      // }

      let generalDomainNames = [
        "gmail",
        "yahoo",
        "aol",
        "mac",
        "cox",
        "frontier",
        "msn",
        "gammana",
        "hotmail",
        "pacbell",
        "att",
        "sbcglobal",
        "windstream",
        "verizon",
        "comcast",
        "ampcocontracting",
      ];
      let domain,
        tempEmail = companyProfile.emailId.value;
      let tempBusinessName = tempEmail.split("@")[1].split(".");
      tempBusinessName.pop();
      let business_name = tempBusinessName.join(".");
      if (generalDomainNames.includes(business_name)) {
        domain = tempEmail.split("@")[0];
      } else {
        domain = business_name;
      }

      let emodForMail;

      for (let state in emodStatesData) {
        let emodObj = {};
        if (emodStatesData[state].response) {
          emodObj = {
            value_1: emodStatesData[state].rate1.value,
            effective_date_1: moment(emodStatesData[state].date1.value).format(
              "YYYY-MM-DD"
            ),
          };

          if (emodForMail == null && emodStatesData[state].rate1.value)
            emodForMail = emodStatesData[state].rate1.value;

          emodObj.stateList =
            state === "ncci" ? emodStatesData[state].stateList : [state];
          if (emodStatesData[state].displayMore) {
            emodObj.value_2 = emodStatesData[state].rate2.value;
            emodObj.effective_date_2 = moment(
              emodStatesData[state].date2.value
            ).format("YYYY-MM-DD");
          }

          //dataBody.common.emod.push(emodObj);
        }
      }

      let carrierEligBody = {};
      let payrollMap = {};
      for (let addressBlockNo in childrenLoc) {
        let name = `line1_line2_${childrenLoc[addressBlockNo].cityName.value}_${
          childrenLoc[addressBlockNo].state.value
        }_${
          (childrenLoc[addressBlockNo].zipCode ||
            childrenLoc[addressBlockNo].zipcode)["value"]
        }`;
        let officesObj = {
          name,
          state: childrenLoc[addressBlockNo].state.value.toLowerCase(),
          employees: [],
          carriers: ["carrier_n"],
        };
        if (!(`${name}_${addressBlockNo}` in carrierEligBody)) {
          carrierEligBody[`${name}_${addressBlockNo}`] = [];
        }
        for (let addressChildNo in childrenLoc[addressBlockNo].classCodesInfo) {
          let classCodeDescArray = childrenLoc[addressBlockNo].classCodesInfo[
            addressChildNo
          ].classCodeDescription.value.split(":");

          let payroll =
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].payroll
              .value;

          let code = classCodeDescArray.shift();
          let desc = classCodeDescArray.join(":").trim();
          let state = childrenLoc[addressBlockNo].state.value.toLowerCase();

          carrierEligBody[`${name}_${addressBlockNo}`].push({
            code,
            description: desc,
            state,
          });

          if (!payrollMap[state]) {
            payrollMap[state] = {};
          }

          payrollMap[state][code] = payroll;

          let classCodeObj = {
            code,
            payroll: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].payroll.value
              .split("$")[1]
              .split(",")
              .join(""),
            ft: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].ft.value.trim(),
            pt: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].pt.value.trim(),
            desc: desc.toLowerCase(),
          };
          officesObj.employees.push(classCodeObj);
        }
      }

      let prospectAddresses = {};

      // create the prospect address object
      for (let stateIndex in childrenLoc) {
        prospectAddresses[stateIndex] = {
          stateCode: childrenLoc[stateIndex].state.value,
          addressId: childrenLoc[stateIndex].number,
          classCodes: [],
        };
        for (let classCodeIndex in childrenLoc[stateIndex].classCodesInfo) {
          let classCodeDescArray = childrenLoc[stateIndex].classCodesInfo[
            classCodeIndex
          ].classCodeDescription.value.split(":");

          let code = classCodeDescArray.shift();
          let desc = classCodeDescArray.join(":").trim();

          prospectAddresses[stateIndex].classCodes.push({
            code,
            payroll: numeral(
              childrenLoc[stateIndex].classCodesInfo[classCodeIndex].payroll
                .value
            )
              .value()
              .toString(),
            pt: childrenLoc[stateIndex].classCodesInfo[
              classCodeIndex
            ].pt.value.trim(),
            ft: childrenLoc[stateIndex].classCodesInfo[
              classCodeIndex
            ].ft.value.trim(),
            description: desc,
          });
          // quoteFactors.payroll += numeral(
          //   childrenLoc[stateIndex].classCodesInfo[classCodeIndex].payroll.value
          // ).value();
        }
      }

      let currentDate = Math.floor(Date.now()).toString();
      let uuid = uuidv4();

      sessionStorage.setItem("address", JSON.stringify(["place"]));
      sessionStorage.setItem(
        "sortKeyList",
        JSON.stringify([`${currentDate}@${uuid}_${"carrier"}+${"place"}`])
      );

      let quoteData = {
        uuid,
        date: currentDate,
      };

      sessionStorage.setItem("quoteData", JSON.stringify(quoteData));

      let tempData = {
        carrier_email_id: "carrier@gmail.com",
        historicalClaims: historicalClaims || {},
        historicalClaimsCheck: historicalClaimsCheck || {},
        uuid_carrier: `${currentDate}@${uuid}_${"carrier"}+${"place"}`,
        date: currentDate,
        healthQues: companyProfile.healthQues.value,
        effective_date: moment(companyProfile.effectiveDate.value).format(
          "YYYY-MM-DD"
        ),
        expiration_date: moment(companyProfile.effectiveDate.value)
          .add(1, "y")
          .format("YYYY-MM-DD"),
        carrier: "carrier",
        prospect_addresses: prospectAddresses,
        contact_person: companyProfile.primaryContact.value,
        user_email_id: companyProfile.emailId.value,
        phone_number: companyProfile.phoneNumber.value,
        company_name: companyProfile.companyName.value,
        fein: companyProfile.fein.value,
        liability_limitId: "100/100/500",
        uuid: uuid,
        domain,
        doc_status: "not_uploaded",
        emodStatesData,
        peoType,
      };

      if (salespersonCheck) {
        tempData.agent_email = salespersonName;
      }

      axios
        .post(awsUrl + "/api/postUsersData", tempData)
        .then((res) => {
          console.log("posted data", res);
          sessionStorage.setItem("formStage", "two");
          window.location.reload();
        })
        .catch((error) => {
          console.log("error in posting data", error);
        })
        .finally(() => {
          $("#loader").css("display", "none");
        });

      // let quoteFactors = {
      //   teap: 0,
      //   total: 0,
      //   futaCharge: 0,
      //   ficaCharge: 0,
      //   sutaCharge: 0,
      //   payroll: 0,
      //   adminFee: 0,
      // };

      // let eligibilityEmailData = {
      //   company_name: companyProfile.companyName.value,
      //   contact_person: companyProfile.primaryContact.value,
      //   effective_date: companyProfile.effectiveDate.value,
      //   phone_number: companyProfile.phoneNumber.value,
      //   user_email_id: companyProfile.emailId.value,
      //   emod: emodForMail === 0 || emodForMail == null ? 1 : emodForMail,
      // };

      // axios
      //   .post(awsUrl + "/api/getCarrierElig", JSON.stringify(carrierEligBody))
      //   .then(async (res) => {
      //     let rejectFlag = false;
      //     let data = res.data,
      //       nonEligibleMap;
      //     for (let location in data) {
      //       for (let carrier in data[location]) {
      //         if (
      //           data[location][carrier] &&
      //           data[location][carrier].value === "no"
      //         ) {
      //           if (!rejectFlag) {
      //             rejectFlag = true;
      //           }
      //           let excludedList = data[location][carrier].excludedList;
      //           for (let item of excludedList) {
      //             item.payroll = payrollMap[item.state][item.class_code];
      //           }
      //           if (!nonEligibleMap) {
      //             nonEligibleMap = {
      //               excludedList: [],
      //               currProspect: currProspectDetails,
      //               timestamp: Math.floor(Date.now()).toString(),
      //               status: "NEW",
      //               email: companyProfile.emailId.value,
      //               domain,
      //             };
      //           }

      //           nonEligibleMap.excludedList.push(...excludedList);

      //           if (salespersonCheck) {
      //             nonEligibleMap.origin = "sales_person";
      //           } else if (checkMailCampaignUser()) {
      //             nonEligibleMap.origin = "email_campaign";
      //           } else {
      //             nonEligibleMap.origin = "direct_user";
      //           }
      //         }
      //       }
      //     }

      //     if (!rejectFlag) {
      //       axios
      //         .post(awsUrl + "/api/getQuote", JSON.stringify(dataBody))
      //         .then(async (res) => {
      //           let dropped = true;
      //           let data = res.data;
      //           for (let location in data) {
      //             if (data[location]["carrier_n"] !== "Dropped") {
      //               dropped = false;
      //               quoteFactors.adminFee =
      //                 data[location]["carrier_n"].admin_fee;
      //               quoteFactors.teap += Number(
      //                 data[location]["carrier_n"].total_estimated_annual_premium
      //               );
      //               quoteFactors.total += Number(
      //                 data[location]["carrier_n"].total
      //               );
      //               quoteFactors.futaCharge = Number(
      //                 data[location]["carrier_n"].futa
      //               );
      //               quoteFactors.ficaCharge = Number(
      //                 data[location]["carrier_n"].fica
      //               );
      //               quoteFactors.sutaCharge = Number(
      //                 data[location]["carrier_n"].suta
      //               );
      //             }
      //           }

      //           if (!dropped) {
      //             let prospectAddresses = {};

      //             // create the prospect address object
      //             for (let stateIndex in childrenLoc) {
      //               prospectAddresses[stateIndex] = {
      //                 stateCode: childrenLoc[stateIndex].state.value,
      //                 addressId: childrenLoc[stateIndex].number,
      //                 classCodes: [],
      //               };
      //               for (let classCodeIndex in childrenLoc[stateIndex]
      //                 .classCodesInfo) {
      //                 let classCodeDescArray = childrenLoc[
      //                   stateIndex
      //                 ].classCodesInfo[
      //                   classCodeIndex
      //                 ].classCodeDescription.value.split(":");

      //                 let code = classCodeDescArray.shift();
      //                 let desc = classCodeDescArray.join(":").trim();

      //                 prospectAddresses[stateIndex].classCodes.push({
      //                   code,
      //                   payroll: numeral(
      //                     childrenLoc[stateIndex].classCodesInfo[classCodeIndex]
      //                       .payroll.value
      //                   )
      //                     .value()
      //                     .toString(),
      //                   pt: childrenLoc[stateIndex].classCodesInfo[
      //                     classCodeIndex
      //                   ].pt.value.trim(),
      //                   ft: childrenLoc[stateIndex].classCodesInfo[
      //                     classCodeIndex
      //                   ].ft.value.trim(),
      //                   description: desc,
      //                 });
      //                 quoteFactors.payroll += numeral(
      //                   childrenLoc[stateIndex].classCodesInfo[classCodeIndex]
      //                     .payroll.value
      //                 ).value();
      //               }
      //             }

      //             let currentDate = Math.floor(Date.now()).toString();
      //             let visitTimestamp = sessionStorage.getItem("visitTimestamp");
      //             let uuid = sessionStorage.getItem("uuid");
      //             if (!uuid) {
      //               uuid = uuidv4();
      //             }
      //             let sortKeyList = [];
      //             let address = [];
      //             let quoteData;

      //             let origin;
      //             let salesperson_email;

      //             if (salespersonCheck) {
      //               origin = "sales_person";
      //               salesperson_email = salespersonName;
      //             } else if (checkMailCampaignUser()) {
      //               origin = "email_campaign";
      //             } else {
      //               origin = "direct_user";
      //             }

      //             for (let place in data) {
      //               address.push(place);
      //               for (let carrier in data[place]) {
      //                 if (data[place][carrier] !== "Dropped") {
      //                   let tempData = {
      //                     uuid_carrier: `${currentDate}@${uuid}_${carrier}+${place}`,
      //                     date: currentDate,
      //                     healthQues: companyProfile.healthQues.value,
      //                     visitDate: visitTimestamp,
      //                     effective_date: moment(
      //                       companyProfile.effectiveDate.value
      //                     ).format("YYYY-MM-DD"),
      //                     expiration_date: moment(
      //                       companyProfile.effectiveDate.value
      //                     )
      //                       .add(1, "y")
      //                       .format("YYYY-MM-DD"),
      //                     prospect_addresses: prospectAddresses,
      //                     contact_person: companyProfile.primaryContact.value,
      //                     user_email_id: companyProfile.emailId.value,
      //                     phone_number: companyProfile.phoneNumber.value,
      //                     company_name: companyProfile.companyName.value,
      //                     fein: companyProfile.fein.value,
      //                     liability_limitId: "100/100/500",
      //                     uuid: uuid,
      //                     carrier: `${carrier}+${place}`,
      //                     carrier_email_id: data[place][carrier].carrier_email,
      //                     carrier_location_data: data[place][carrier],
      //                     domain,
      //                     doc_status: "not_uploaded",
      //                     emodStatesData,
      //                     origin,
      //                     peoType,
      //                   };
      //                   if (tempData.origin === "sales_person") {
      //                     tempData.salesperson_email = salesperson_email;
      //                   }
      //                   sortKeyList.push(tempData.uuid_carrier);
      //                   carrierUserData.push(tempData);
      //                 }
      //               }
      //             }

      //             quoteData = {
      //               uuid,
      //               date: currentDate,
      //             };

      //             sessionStorage.setItem(
      //               "sortKeyList",
      //               JSON.stringify(sortKeyList)
      //             );
      //             sessionStorage.setItem(
      //               "quoteData",
      //               JSON.stringify(quoteData)
      //             );
      //             sessionStorage.setItem("address", JSON.stringify(address));

      //             if (sessionStorage.getItem("landingEmail")) {
      //               let uploadDataa = [];
      //               let userTrackingData = {};
      //               userTrackingData.email = sessionStorage.getItem(
      //                 "landingEmail"
      //               );
      //               userTrackingData.timestamp = visitTimestamp;
      //               userTrackingData.domain = sessionStorage.getItem("domain");
      //               userTrackingData.companyProfile = JSON.parse(
      //                 sessionStorage.getItem("currProspect")
      //               ).companyProfile;
      //               userTrackingData.emodStatesData = JSON.parse(
      //                 sessionStorage.getItem("currProspect")
      //               ).emodStatesData;
      //               userTrackingData.indicationCost = quoteFactors.total;
      //               userTrackingData.payrollData = JSON.parse(
      //                 sessionStorage.getItem("currProspect")
      //               ).childrenLoc;
      //               userTrackingData.uuid = sessionStorage.getItem("uuid");
      //               uploadDataa.push(userTrackingData);
      //               // console.log("*************************");
      //               // console.log("********uploadDataa******");
      //               // console.log(uploadDataa);
      //               await axios
      //                 .post(
      //                   awsUrl2 + "/api/updateUsersTrackingData",
      //                   uploadDataa
      //                 )
      //                 .then((res) => {
      //                   console.log("posted data", res);
      //                 })
      //                 .catch((error) => {
      //                   console.log(
      //                     "error in posting data to user tracking",
      //                     error
      //                   );
      //                 });
      //             }

      //             let prevIndex = 0;
      //             let nextIndex = 100;
      //             let uploadData;
      //             for (
      //               let i = 0;
      //               i < Math.floor(carrierUserData.length / 100) + 1;
      //               i++
      //             ) {
      //               uploadData = carrierUserData.slice(prevIndex, nextIndex);
      //               axios
      //                 .post(awsUrl + "/api/postUsersData", uploadData)
      //                 .then((res) => {
      //                   console.log("posted data", res);
      //                 })
      //                 .catch((error) => {
      //                   console.log("error in posting data", error);
      //                 })
      //                 .finally(() => {
      //                   sessionStorage.setItem("formStage", "three");
      //                   sessionStorage.setItem(
      //                     "quoteFactors",
      //                     JSON.stringify(quoteFactors)
      //                   );
      //                   $("#loader").css("display", "none");
      //                   window.location.reload();
      //                 });
      //             }
      //           } else {
      //             sessionStorage.setItem("formStage", "three");
      //             $("#loader").css("display", "none");
      //             window.location.reload();
      //           }
      //         })
      //         .catch((error) => {
      //           console.log("error", error);
      //           sessionStorage.setItem(
      //             "quoteFactors",
      //             JSON.stringify(quoteFactors)
      //           );
      //           $("#loader").css("display", "none");
      //           window.location.reload();
      //         });
      //     } else {
      //       try {
      //         axios
      //           .post(
      //             awsUrl2 + "/api/sendEligibilityEmail",
      //             eligibilityEmailData
      //           )
      //           .then((res) => {
      //             axios
      //               .post(
      //                 awsUrl2 + "/api/putNonEligibleData",
      //                 JSON.stringify(nonEligibleMap)
      //               )
      //               .then((res) => console.log("uploaded"))
      //               .catch((err) => console.log(err))
      //               .finally(() => {
      //                 $("#loader").css("display", "none");
      //                 updatePopUpData({
      //                   show: true,
      //                   title: "Thank you for choosing us!!",
      //                   children: (
      //                     <>
      //                       <br></br>
      //                       <span style={{ fontWeight: "bold" }}>
      //                         Our Team will get back to you in the next 24
      //                         Hours.
      //                       </span>
      //                     </>
      //                   ),
      //                   acceptBtn: "Okay",
      //                   disableNoButton: true,
      //                 });
      //               });
      //           })
      //           .catch((error) => console.log("error: ", error))
      //           .finally(() => {
      //             $("#loader").css("display", "none");
      //           });
      //       } catch (error) {
      //         console.log("error in sendStatusEmail-API");
      //         $("#loader").css("display", "none");
      //       }
      //     }
      //   })
      //   .catch((err) => {
      //     console.log("error in eligibility api", err);
      //     $("#loader").css("display", "none");
      //   });
    }
  } catch (error) {
    console.log("error", error);
    $("#loader").css("display", "none");
  }
};

export function updateQuesList(e, key, value) {
  let { quesList } = this.state;
  let index = e.target.id.split("-")[1];
  quesList[index][key] = value;
  this.setState({ quesList });
}

export function updateAddQuesList(e, key, value) {
  let { quesListAdd } = this.state;
  let index = Number(e.target.id.split("-")[1]) - 25;
  quesListAdd[index][key] = value;
  this.setState({ quesListAdd });
}

export function appendAddQuesList(index, questionsList) {
  let { quesListAdd } = this.state;
  questionsList.forEach((question) => {
    quesListAdd[index] = question;
    index++;
  });
  this.setState({ quesListAdd });
}

export function updateEmodRates(i, value, key) {
  let { quesListAdd } = this.state;
  if (!quesListAdd[i].rates) {
    quesListAdd[i].rates = {};
  }
  quesListAdd[i].rates[key] = value;
  this.setState({ quesListAdd });
}
