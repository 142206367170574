import React, { Component } from "react";
import ReactDOM from "react-dom";
import Loader from "../common/Loader";
import FormLogin from "../subcompo/sections-login/FormLogin";
import FormReset from "../subcompo/sections-login/FormReset";
import Footer from "../common/Footer";
import FormChangePass from "../subcompo/sections-login/FormChangePass";
import Header from "../common/Header";
import ImageBlock from "../subcompo/sections-home/ImageBlock";

class Login extends Component {
  componentDidMount() {
    ReactDOM.findDOMNode(this).scrollTop = 0;
  }
  render() {
    return (
      <div>
        <div className="d-flex">
          <Header />
        </div>
        <ImageBlock scrollSelector="#lgin" />
        <div className="tab-content tab-content-login mb-5 mt-5">
          <FormLogin />
          <FormReset />
          <FormChangePass />
          <Loader />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Login;
