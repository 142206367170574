let mode;

if (process.env.REACT_APP_MODE) {
  // mode = "local2";
  mode = process.env.REACT_APP_MODE;
} else {
  mode = "local2";
}

console.log("env", process.env);

var localURL = "http://localhost:3000";

// server_coreSetURL1;
export var awsUrl =
  "https://two4smo4ee.execute-api.us-east-1.amazonaws.com/dev";
var awsUrlBeta = "https://ja8cu8j53k.execute-api.us-east-1.amazonaws.com/beta";
var awsUrlProd = "https://1h3juvjogh.execute-api.us-east-1.amazonaws.com/prod";

// server_coreSetURL2;
export var awsUrl2 =
  "https://pswwt60y0g.execute-api.us-east-1.amazonaws.com/dev";
var awsUrlBeta2 = "https://eeq3847o2b.execute-api.us-east-1.amazonaws.com/beta";
var awsUrlProd2 = "https://23vlfiousg.execute-api.us-east-1.amazonaws.com/prod";

// dashboard URL
export var dashboardUrl =
  "https://dmuwt3vd0e.execute-api.us-east-1.amazonaws.com/dev";
var dashboardUrlProd = "";

if (mode === "local1") {
  awsUrl = localURL;
} else if (mode === "local2") {
  awsUrl2 = localURL;
} else if (mode === "locald") {
  dashboardUrl = localURL;
} else if (mode === "prod") {
  awsUrl = awsUrlProd;
  awsUrl2 = awsUrlProd2;
  dashboardUrl = dashboardUrlProd;
} else if (mode === "beta") {
  awsUrl = awsUrlBeta;
  awsUrl2 = awsUrlBeta2;
  dashboardUrl = dashboardUrlProd;
}

export const versionNumber = "1.0.6";
