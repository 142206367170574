import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { Form } from "react-bootstrap";
import Newcode from "./Newcode";
import StateList from "../../common/StateList";
import { deleteAddress } from "../../../utils/session_data";
import axios from "axios";
import { awsUrl } from "../../../config";

class CompLoc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationInfo: { state: {}, zipCode: {}, cityName: {} },
    };
  }

  componentDidMount() {
    this.setState({ locationInfo: this.props.locationInfo });
  }

  componentDidUpdate() {
    if (this.props.locationInfo !== this.state.locationInfo) {
      this.setState({ locationInfo: this.props.locationInfo });
    }
  }

  addClassCode = (number, classCodesInfo, updateLocationInfo) => {
    let codeCount = Object.keys(classCodesInfo).reduce((prev, val) => {
      return Math.max(Number(val), Number(prev));
    });
    updateLocationInfo(null, number, null, Number(codeCount) + 1);
  };

  getMinMax = (addressBlockNo, addressChildNo) => {
    const { locationInfo } = this.state;
    const { effectiveDate, updateLocationError } = this.props;
    const {
      ft,
      pt,
      payroll,
      classCodeDescription,
    } = locationInfo.classCodesInfo[addressChildNo];
    const state = locationInfo.state.value.toLowerCase();
    const zip = "11111";

    let classCode = classCodeDescription.value.split(":")[0];

    let data = {
      code: classCode,
      pt: pt.value.trim(),
      ft: ft.value.trim(),
      payroll: payroll.value
        .split("$")[1]
        .split(",")
        .join(""),
    };

    let bodyData = {
      employees: [data],
      state: state,
      date_from: effectiveDate.value.format("YYYY-MM-DD"),
      location: zip,
    };

    // axios
    //   .post(awsUrl + "/api/getMinMaxPremium", JSON.stringify(bodyData))
    //   .then((response) => {
    //     if (response.data === "Not eligible") {
    //       updateLocationError(
    //         addressBlockNo,
    //         addressChildNo,
    //         "classCodeDescription",
    //         true
    //       );
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     updateLocationError(
    //       addressBlockNo,
    //       addressChildNo,
    //       "classCodeDescription",
    //       true
    //     );
    //   });
  };

  handleMinMax = (addressBlockNo, addressChildNo) => {
    const { locationInfo } = this.state;
    const keyList = ["ft", "pt", "payroll", "classCodeDescription"];
    let allClassCodeVals;
    if (locationInfo && locationInfo.classCodesInfo[addressChildNo]) {
      allClassCodeVals = keyList.every((e) => {
        return (
          e in locationInfo.classCodesInfo[addressChildNo] &&
          locationInfo.classCodesInfo[addressChildNo][e].value &&
          locationInfo.classCodesInfo[addressChildNo][e].value.trim() !== ""
        );
      });
    }
    if (allClassCodeVals && locationInfo.state.value) {
      this.getMinMax(addressBlockNo, addressChildNo);
    }
  };

  render() {
    const {
      setStateAndCity,
      updateLocationInfo,
      removeLocation,
      removeClassCode,
      updateClassCodesInfo,
      resetClassCodeDescriptionData,
      index,
      updateLocationError,
      locationCount,
    } = this.props;
    const { locationInfo } = this.state;
    const number = locationInfo ? locationInfo.number : null;
    return (
      <div id={"officeLoc" + number} className="mb-2">
        {/* <div className="row mb-3">
          <h6 className="col-sm-12">Location {index} </h6>
        </div> */}
        <div className="row grey">
          {/* <div className="col-sm-2" id="zip">
            <Form.Group>
              <label
                for={"zipCode-" + number}
                className="mandatory"
                title="ZIP Code"
              >
                ZIP Code
              </label>
              <NumberFormat
                name="zipCode"
                id={"zipCode" + number}
                format="#####"
                type="text"
                onChange={(event) =>
                  updateLocationInfo(event.target.value, number, "zipCode")
                }
                value={locationInfo ? locationInfo.zipCode.value : ""}
                style={
                  locationInfo && locationInfo.zipCode.error
                    ? { borderColor: "red" }
                    : {}
                }
                onBlur={(event) => setStateAndCity(event.target.value, number)}
                placeholder="ZipCode"
                autoComplete="off"
              />
              <p className="errMsg addressErrs" />
            </Form.Group> 
          </div> */}
          <div className="col-sm-2" id="state">
            <Form.Group>
              <label className="mandatory" title="State">
                State
              </label>
              <StateList
                state={locationInfo ? locationInfo.state.value : null}
                addressBlockNo={number}
                locationInfo={locationInfo}
                updateLocationInfo={updateLocationInfo}
                resetClassCodeDescriptionData={resetClassCodeDescriptionData}
              />
              <p className="errMsg addressErrs" />
            </Form.Group>
          </div>
          {/* <div className="col-sm-3">
            <Form.Group>
              <label
                for={"cityName-" + number}
                className="mandatory"
                title="City"
              >
                City
              </label>
              <input
                id={"cityName-" + number}
                name="cityName"
                type="text"
                onChange={(event) =>
                  updateLocationInfo(event.target.value, number, "cityName")
                }
                value={locationInfo ? locationInfo.cityName.value : ""}
                style={
                  locationInfo && locationInfo.cityName.error
                    ? { borderColor: "red" }
                    : {}
                }
                placeholder="City Name"
                autoComplete="off"
              />
              <p className="errMsg addressErrs" />
            </Form.Group>
          </div> */}
          {locationCount > 1 && (
            <div className="col-xs-1 text-center address-delete">
              <button
                type="button"
                onClick={(event) => {
                  removeLocation(number);
                }}
                className="rmvLoc btnInvisible transpButton"
              >
                <img
                  onClick={(event) => deleteAddress(number)}
                  src={require("../../../images/delete4.png")}
                  alt=""
                />
              </button>
            </div>
          )}
        </div>
        <div className="ml-3 mr-3">
          {locationInfo &&
            locationInfo.state.value &&
            locationInfo.classCodesInfo && (
              <div className="row mb-3">
                <h6 className="col-sm-12">
                  <b>Class Code Information</b>
                </h6>
              </div>
            )}
          {locationInfo &&
            locationInfo.state.value &&
            locationInfo.classCodesInfo &&
            Object.keys(locationInfo.classCodesInfo).map((e) => {
              return (
                <Newcode
                  key={e}
                  state={locationInfo.state.value}
                  addressChildNo={e}
                  addressBlockNo={number}
                  removeClassCode={removeClassCode}
                  classCodesInfo={locationInfo.classCodesInfo}
                  updateLocationError={updateLocationError}
                  updateClassCodesInfo={updateClassCodesInfo}
                  handleMinMax={this.handleMinMax}
                />
              );
            })}
          {locationInfo &&
            locationInfo.state.value &&
            locationInfo.classCodesInfo && (
              <div className="calculator">
                <div>
                  <button
                    type="button"
                    className="btnInvisible addLoc transpButton p-0"
                    onClick={() =>
                      this.addClassCode(
                        number,
                        locationInfo.classCodesInfo,
                        updateLocationInfo
                      )
                    }
                  >
                    <img src={require("../../../images/plus4.png")} /> Add class
                    code
                  </button>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default CompLoc;
