import React, { Component } from "react";
import Header from "../common/Header";
import ImageBlock from "../subcompo/sections-home/ImageBlock";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router-dom";
import { awsUrl2 } from "../../config";
import { Auth } from "aws-amplify";
import axios from "axios";
import Popup from "../../component/common/Popup";
import moment from "moment";
const icon = require("../../images/black-card-2.png");

class AgentSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupData: {
        title: "",
        children: <></>,
        acceptBtn: "",
        acceptBtnCallback: () => {},
        show: false,
      },
      signUpForm: {
        name: {
          value: "",
          error: false,
        },
        company: {
          value: "",
          error: false,
        },
        email: {
          value: "",
          error: false,
        },
        mobile: {
          value: "",
          error: false,
        },
        address: {
          value: "",
          error: false,
        },
        primary_state_of_business: {
          value: "",
          error: false,
        },
        fein: {
          value: "",
          error: false,
        },
        comment: {
          value: "",
          error: false,
        },
      },
    };
  }

  componentDidMount() {
    sessionStorage.clear();
    let { signUpForm } = JSON.parse(JSON.stringify(this.state));

    // autofill the agent data
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let user = urlParams.get("user");

    if (user) {
      sessionStorage.setItem("emailCampaignUser", true);
      user = user.toLowerCase();
      this.setState({ loadingData: true });
      axios
        .get(awsUrl2 + "/api/getAgentInputData/" + user)
        .then((response) => {
          if (response.status === 200) {
            for (let key in response.data) {
              if (key in signUpForm) {
                signUpForm[key].value = response.data[key];
              }
            }
            this.setState({ signUpForm });
          }
        })
        .catch((error) => {
          console.log("error while fetching agent data", error);
        })
        .finally(() => {
          this.setState({ loadingData: false });
        });
    }
  }

  updatePopUpData = (pData) => {
    this.setState({ popupData: pData });
  };

  generateRandomPassword = (length) => {
    let uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let lowercase = "abcdefghijklmnopqrstuvwxyz";
    let numbers = "0123456789";
    let symbols = "!@#$%^&*";
    let all = uppercase + lowercase + numbers + symbols;
    let password = "";
    let character = Math.floor(Math.random() * uppercase.length);
    password += uppercase.substring(character, character + 1);
    character = Math.floor(Math.random() * lowercase.length);
    password += lowercase.substring(character, character + 1);
    character = Math.floor(Math.random() * numbers.length);
    password += numbers.substring(character, character + 1);
    character = Math.floor(Math.random() * symbols.length);
    password += symbols.substring(character, character + 1);
    for (let index = 0; index < length - 4; index++) {
      character = Math.floor(Math.random() * all.length);
      password += all.substring(character, character + 1);
    }

    return password;
  };

  handleSubmitButton = async (e) => {
    this.setState({ errorMessage: undefined });
    let { signUpForm } = this.state;
    let errFlag = false;
    let emailCampaignUser =
      sessionStorage.getItem("emailCampaignUser") === "true" ? true : false;

    // validate the signup form
    for (let key in signUpForm) {
      if (key === "comment") continue;
      if (!signUpForm[key].value || signUpForm[key].error) {
        if (!errFlag) errFlag = true;
        signUpForm[key].error = true;
      }
    }
    if (errFlag) {
      this.setState({ signUpForm });
      e.preventDefault();
    } else {
      // convert fein to a 9 digit string
      let fein = signUpForm.fein.value.split("-").join("").trim();
      let len = fein.length,
        dummystr = "000000000";

      if (len > 0 && len < 9) {
        fein = dummystr.slice(0, 9 - len) + "" + fein;
      }

      // check if the fein/user already exists
      let res = await axios.get(
        awsUrl2 + `/api/checkFeinEmail/${fein}/${signUpForm.email.value}`
      );

      let timestamp = moment().format("x");

      if (
        (res.data.fein_exists && res.data.email_exists) ||
        (!res.data.fein_exists && res.data.email_exists)
      ) {
        this.setState({
          errorMessage: "An account with the given email already exists.",
        });
      } else if (res.data.fein_exists && !res.data.email_exists) {
        let password = this.generateRandomPassword(8);
        let promiseList;

        promiseList = [
          axios.post(awsUrl2 + "/api/updateAgentInputData", {
            fein,
            email: signUpForm.email.value,
          }),
          Auth.signUp({
            username: signUpForm.email.value.toLowerCase(),
            password,
            attributes: {
              "custom:stage": process.env.REACT_APP_MODE,
              "custom:pass": password,
              "custom:fein": fein,
              "custom:name": signUpForm.name.value,
              "custom:company": signUpForm.company.value,
              "custom:mobile": signUpForm.mobile.value,
              "custom:address": signUpForm.address.value,
              "custom:state": signUpForm.primary_state_of_business.value,
              "custom:timestamp": timestamp,
            },
          }),
        ];

        if (!emailCampaignUser) {
          promiseList.push(
            axios.post(awsUrl2 + "/api/putDirectUserTracking", {
              email: signUpForm.email.value.toLowerCase(),
              fein,
              name: signUpForm.name.value,
              company: signUpForm.company.value,
              mobile: signUpForm.mobile.value,
              address: signUpForm.address.value,
              state: signUpForm.primary_state_of_business.value,
              timestamp,
            })
          );
        }

        Promise.all(promiseList)
          .then((res) => {
            this.updatePopUpData({
              show: true,
              title: "Account already exists!",
              children: (
                <>
                  <br></br>
                  <span style={{ fontWeight: "bold" }}>
                    Account already exists for {signUpForm.company.value}.
                    Credentials will be shared via email.
                  </span>
                </>
              ),
              acceptBtn: "Okay",
              disableNoButton: true,
            });
          })
          .catch((error) => {
            console.log("error while updating fein", error);
            this.setState({
              errorMessage: "An error occured while signing you up.",
            });
          });
      } else {
        let promiseList;
        promiseList = [
          axios.post(awsUrl2 + "/api/updateAgentInputData", {
            fein,
            email: signUpForm.email.value,
          }),
        ];

        if (!emailCampaignUser) {
          promiseList.push(
            axios.post(awsUrl2 + "/api/putDirectUserTracking", {
              email: signUpForm.email.value.toLowerCase(),
              fein,
              name: signUpForm.name.value,
              company: signUpForm.company.value,
              mobile: signUpForm.mobile.value,
              address: signUpForm.address.value,
              state: signUpForm.primary_state_of_business.value,
              timestamp,
            })
          );
        }

        Promise.all(promiseList)
          .then((res) => {
            sessionStorage.setItem("signUpForm", JSON.stringify(signUpForm));
            this.props.history.push("/agent-agreement");
          })
          .catch((error) => {
            console.log("error while updating fein", error);
            this.setState({
              errorMessage: "An error occured while signing you up.",
            });
          });
      }
    }
  };

  setSignupField = (name, value) => {
    let { signUpForm } = this.state;
    signUpForm[name].value = value;
    signUpForm[name].error = false;
    this.setState({ signUpForm });
  };

  render() {
    let { signUpForm, errorMessage, popupData } = this.state;
    return (
      <div className="signup align-items-center">
        <Header />
        <ImageBlock scrollSelector=".home-form" />
        <HomeForm
          popupData={popupData}
          updatePopUpData={this.updatePopUpData}
          setSignupField={this.setSignupField}
          signUpForm={signUpForm}
          handleSubmitButton={this.handleSubmitButton}
          errorMessage={errorMessage}
        />
        <WhyWorkComp />
        <MinRequirements />
      </div>
    );
  }
}

const MinRequirements = () => {
  return (
    <div className="minReq">
      <b className="display-4">Minimum Requirements</b>
      <br />
      <i>
        Program eligibility and underwriting will be based on these criteria
      </i>
      <ul>
        <li>
          Signed, completed ACORD 130 Applications for individual clients with
          Ex-Mod and Carrier/Premium History
        </li>
        <li>Detailed explanation of losses above $25,000</li>
        <li>5 Years of currently values loss runs</li>
        <li>Payroll summary (desired but not required)</li>
        <li>Target Premium</li>
      </ul>
    </div>
  );
};

const WhyWorkComp = () => {
  return (
    <div className="whyWorkComp">
      <b className="display-4">
        Why {process.env.REACT_APP_MODE === "beta" ? "Us" : "Work Comp Now"}
      </b>
      <div className="row">
        <div className="col-lg-6 flex-row align-items-center">
          <img src={icon} alt="calendar" />
          <p>No minimum performance requirements for appointment</p>
        </div>
        <div className="col-lg-6 flex-row align-items-center">
          <img src={icon} alt="calendar" />
          <p>Highly reactive and attentive Sales and Service support</p>
        </div>
        <div className="col-lg-6 flex-row align-items-center">
          <img src={icon} alt="calendar" />
          <p>Very competitive commissions</p>
        </div>
        <div className="col-lg-6 flex-row align-items-center">
          <img src={icon} alt="calendar" />
          <p>
            Your business is protected we donot honor broker of Record letters
          </p>
        </div>
      </div>
    </div>
  );
};

const HomeForm = (props) => {
  let { signUpForm, errorMessage, popupData, updatePopUpData } = props;

  return (
    <div className="home-form w-100 p-5">
      <form>
        <b className="display-4 mb-5">
          {"Get Appointed Today by Completing this Form"}
        </b>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="row">
              <div className="col-lg-12">
                <input
                  type="text"
                  id="home-name"
                  value={signUpForm.name.value}
                  onChange={(e) => {
                    props.setSignupField("name", e.target.value);
                  }}
                  style={
                    signUpForm.name.error ? { border: "1px solid red" } : {}
                  }
                  placeholder="Name*"
                  autoComplete="off"
                />
              </div>
              <div className="col-lg-12">
                <input
                  type="text"
                  id="home-company"
                  value={signUpForm.company.value}
                  onChange={(e) => {
                    props.setSignupField("company", e.target.value);
                  }}
                  style={
                    signUpForm.company.error ? { border: "1px solid red" } : {}
                  }
                  placeholder="Company*"
                  autoComplete="off"
                />
              </div>
              <div className="col-lg-12">
                <input
                  type="text"
                  id="home-email"
                  value={signUpForm.email.value}
                  onChange={(e) => {
                    props.setSignupField("email", e.target.value);
                  }}
                  style={
                    signUpForm.email.error ? { border: "1px solid red" } : {}
                  }
                  placeholder="Email*"
                  autoComplete="off"
                />
              </div>
              <div className="col-lg-12">
                <NumberFormat
                  type="text"
                  id="home-mobile"
                  value={signUpForm.mobile.value}
                  onChange={(e) => {
                    props.setSignupField("mobile", e.target.value);
                  }}
                  style={
                    signUpForm.mobile.error ? { border: "1px solid red" } : {}
                  }
                  placeholder="Mobile*"
                  format="(###) ###-####"
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="row">
              <div className="col-lg-12">
                <input
                  type="text"
                  id="home-address"
                  value={signUpForm.address.value}
                  onChange={(e) => {
                    props.setSignupField("address", e.target.value);
                  }}
                  style={
                    signUpForm.address.error ? { border: "1px solid red" } : {}
                  }
                  placeholder="Address*"
                  autoComplete="off"
                />
              </div>
              <div className="col-lg-12">
                <input
                  type="text"
                  id="home-state-business"
                  value={signUpForm.primary_state_of_business.value}
                  onChange={(e) => {
                    props.setSignupField(
                      "primary_state_of_business",
                      e.target.value
                    );
                  }}
                  style={
                    signUpForm.primary_state_of_business.error
                      ? { border: "1px solid red" }
                      : {}
                  }
                  placeholder="Primary State of Business*"
                  autoComplete="off"
                />
              </div>
              <div className="col-lg-12">
                <NumberFormat
                  type="text"
                  id="home-fein"
                  value={signUpForm.fein.value}
                  onChange={(e) => {
                    props.setSignupField("fein", e.target.value);
                  }}
                  style={
                    signUpForm.fein.error ? { border: "1px solid red" } : {}
                  }
                  format="##-#######"
                  placeholder="FEIN*"
                  autoComplete="off"
                />
              </div>
              <div className="col-lg-12">
                <textarea
                  className="p-2"
                  type="text"
                  id="home-comment"
                  value={signUpForm.comment.value}
                  onChange={(e) => {
                    props.setSignupField("comment", e.target.value);
                  }}
                  style={
                    signUpForm.comment.error ? { border: "1px solid red" } : {}
                  }
                  placeholder="Comment"
                  autoComplete="off"
                  // rows="4"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <button
            type="button"
            className="btn btn-primary text-center"
            onClick={(e) => props.handleSubmitButton(e)}
          >
            SUBMIT
          </button>
          {errorMessage && <span className="errMsg mt-2">{errorMessage}</span>}
        </div>
      </form>
      {popupData.show && (
        <Popup popupData={popupData} updatePopUpData={updatePopUpData} />
      )}
    </div>
  );
};
export default withRouter(AgentSignup);
