import React, { Component } from "react";

class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="testimonials d-flex flex-column">
        <div className="testimonials-text text-center">
          “Our experience with SolveHR has been outstanding, we previously
          processed<br></br>payroll and handled HR internally with moderate
          success. SolveHR’s expertise<br></br>helped us to refine processes
          that we completed and turned into streamlined<br></br>systems that do
          not impede our workflow. Our hand-books, time keeping and<br></br>HR
          are now tools we live by, outsourcing to SolveHR is the way to go.”
        </div>
        <div className="testimonials-client-name text-center">
          - Client Testimonial
        </div>
      </div>
    );
  }
}

export default Testimonials;
