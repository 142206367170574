import React, { Component } from "react";
import Header from "../common/Header";
import { awsUrl2 } from "../../config";
import axios from "axios";
import { blobPdfFromBase64String } from "../../utils/common";
import { Auth } from "aws-amplify";
import Popup from "../../component/common/Popup";
const moment = require("moment");

class AgentAgreement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingAgreement: false,
      fileURL: null,
      tNc: false,
      popupData: {
        title: "",
        children: <></>,
        acceptBtn: "",
        acceptBtnCallback: () => {},
        show: false,
      },
    };
  }

  componentDidMount() {
    let signUpForm = JSON.parse(sessionStorage.getItem("signUpForm"));
    if (signUpForm) {
      this.setState({ signUpForm }, () => {
        this.generateAgreement(signUpForm);
      });
    } else {
      this.props.history.push("/agent-signup");
    }
  }

  updatePopUpData = (pData) => {
    this.setState({ popupData: pData });
  };

  generateRandomPassword = (length) => {
    let uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let lowercase = "abcdefghijklmnopqrstuvwxyz";
    let numbers = "0123456789";
    let symbols = "!@#$%^&*";
    let all = uppercase + lowercase + numbers + symbols;
    let password = "";
    let character = Math.floor(Math.random() * uppercase.length);
    password += uppercase.substring(character, character + 1);
    character = Math.floor(Math.random() * lowercase.length);
    password += lowercase.substring(character, character + 1);
    character = Math.floor(Math.random() * numbers.length);
    password += numbers.substring(character, character + 1);
    character = Math.floor(Math.random() * symbols.length);
    password += symbols.substring(character, character + 1);
    for (let index = 0; index < length - 4; index++) {
      character = Math.floor(Math.random() * all.length);
      password += all.substring(character, character + 1);
    }

    return password;
  };

  handleConfirmButtonClick = async () => {
    let { dataToSend, signUpForm } = this.state;
    this.setState({ signupErrorMessage: undefined });
    if (dataToSend && signUpForm) {
      let password = this.generateRandomPassword(8);

      // convert fein to a 9 digit string
      let fein = signUpForm.fein.value
        .split("-")
        .join("")
        .trim();
      let len = fein.length,
        dummystr = "000000000";

      if (len > 0 && len < 9) {
        fein = dummystr.slice(0, 9 - len) + "" + fein;
      }

      try {
        await Auth.signUp({
          username: signUpForm.email.value.toLowerCase(),
          password,
          attributes: {
            "custom:stage": process.env.REACT_APP_MODE,
            "custom:pass": password,
            "custom:fein": fein,
            "custom:name": signUpForm.name.value,
            "custom:company": signUpForm.company.value,
            "custom:mobile": signUpForm.mobile.value,
            "custom:address": signUpForm.address.value,
            "custom:state": signUpForm.primary_state_of_business.value,
            "custom:timestamp": moment().format("x"),
          },
        });

        await axios.post(
          awsUrl2 + "/api/sendBrokerMail",
          JSON.stringify(dataToSend)
        );

        this.updatePopUpData({
          show: true,
          title: "Thank you for choosing us!!",
          children: (
            <>
              <br></br>
              <span style={{ fontWeight: "bold" }}>
                Our Team will get back to you in the next 24 Hours.
              </span>
            </>
          ),
          acceptBtn: "Okay",
          disableNoButton: true,
        });
      } catch (error) {
        console.log("error in setting up user", error);
        this.setState({ signupErrorMessage: error.message });
      }
    }
  };

  generateAgreement = async (signUpForm) => {
    try {
      if (signUpForm) {
        this.setState({ loadingAgreement: true });
        sessionStorage.setItem("signUpForm", JSON.stringify(signUpForm));
        let dataToSend = {
          email: signUpForm.email.value,
          name: signUpForm.name.value,
          location: signUpForm.address.value,
          by1: "Work Comp Now Inc.",
          by2: signUpForm.name.value,
          date1: moment().format("M/D/YYYY"),
          date2: moment().format("M/D/YYYY"),
        };
        await axios
          .post(
            awsUrl2 + "/api/generateBrokerAgreement",
            JSON.stringify(dataToSend)
          )
          .then(async (res) => {
            let pdf = res.data.data;
            let file = blobPdfFromBase64String(pdf);

            var fileURL = URL.createObjectURL(file);
            this.setState({ fileURL, loadingAgreement: false, dataToSend });
          })
          .catch((error) => {
            console.log("error in API", error);
            this.setState({ loadingAgreement: false, dataToSend });
          });
      }
    } catch (error) {
      console.log("error in fetching agreement");
      this.setState({ loadingAgreement: false });
    }
  };

  render() {
    let {
      fileURL,
      loadingAgreement,
      tNc,
      signupErrorMessage,
      popupData,
    } = this.state;
    return (
      <>
        <Header />
        <div className="second flex-row w-100 mb-5">
          <div className="d-flex justify-content-center mb-3">
            <div style={{ width: "70%", height: "645px" }}>
              {fileURL && !loadingAgreement ? (
                <iframe
                  id={`iframePEO`}
                  src={fileURL + "#toolbar=0"}
                  style={{ border: "none", width: "100%", height: "645px" }}
                ></iframe>
              ) : (
                <div className="d-flex align-item-center justify-content-center">
                  LOADING...
                </div>
              )}
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <label htmlFor="tNc">
              <input
                type="checkbox"
                id="tNc"
                name="tNc"
                defaultChecked={tNc}
                onChange={() => this.setState({ tNc: !this.state.tNc })}
              />{" "}
              I agree to the terms &amp; conditions
            </label>
            <button
              id="Confirm"
              className="btn btn-primary"
              disabled={!tNc}
              onClick={() => this.handleConfirmButtonClick()}
            >
              Confirm
            </button>
            {signupErrorMessage && (
              <span className="errMsg mt-2">{signupErrorMessage}</span>
            )}
          </div>
        </div>
        {popupData.show && (
          <Popup popupData={popupData} updatePopUpData={this.updatePopUpData} />
        )}
      </>
    );
  }
}

export default AgentAgreement;
