import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home2 from "./component/pages/Home2";
import AgentSignup from "./component/pages/AgentSignup";
import AgentAgreement from "./component/pages/AgentAgreement";
import NewQuote from "./newcomponent/NewQuote";
import Login from "./component/pages/Login";
import PanelUW from "./component/pages/PanelUW";
import Tabs from "./component/pages/Tabs";
import AgentDashboard from "./component/pages/AgentDashboard";
import AntiPrivateRoute from "./component/common/AntiPrivateRoute";
import PrivateGroupRoute from "./component/common/PrivateGroupRoute";

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <PrivateGroupRoute
            allowedGroup={[process.env.REACT_APP_ADMIN_GROUP]}
            exact
            path="/AdminDashboard"
            component={Tabs}
          />
          <PrivateGroupRoute
            allowedGroup={[process.env.REACT_APP_AGENT_GROUP]}
            exact
            path="/AgentDashboard"
            component={AgentDashboard}
          />
          <PrivateGroupRoute
            exact
            allowedGroup={[process.env.REACT_APP_AGENT_GROUP]}
            path="/Quote"
            component={NewQuote}
          />
          <PrivateGroupRoute
            allowedGroup={[process.env.REACT_APP_CARRIER_GROUP]}
            exact
            path="/PanelUW"
            component={PanelUW}
          />
          <AntiPrivateRoute
            exact
            path="/agent-signup"
            component={AgentSignup}
          />
          <AntiPrivateRoute
            exact
            path="/agent-agreement"
            component={AgentAgreement}
          />
          <AntiPrivateRoute path="/" component={Login} />
        </Switch>
      </Router>
    );
  }
}

export default App;
