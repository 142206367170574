/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_LImmVQRgz",
    "aws_user_pools_web_client_id": "3cbkfdfrg6snvjsis0mtjiqf67",
    "oauth": {}
};


export default awsmobile;
