import React, { Component } from "react";
import ReactDOM from "react-dom";
import { otherAddress, submitPayment } from "../../../utils/payment";
import {
  deleteAllCookies,
  showErrAlert,
  clearErr,
  blobPdfFromBase64String,
} from "../../../utils/common";
import Tabs from "../../common/Tabs";
import { Link } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import Loader from "../../common/Loader";
import { awsUrl, awsUrl2 } from "../../../config";
import { Auth } from "aws-amplify";
import NewProgress from "../../../newcomponent/common/NewProgress";
import LoadingOverlay from "react-loading-overlay";

const jQuery = require("jquery");
const CircularJSON = require("circular-json");
var atob = require("atob");
class FormPayment extends Component {
  constructor(props) {
    super();

    this.state = {
      flag: false,
      url: [],
      showUploadProgress: false,
      compName: "ABC",
      tAndC: true,
      loadingPEO: false,
      fileURL: undefined,
    };
    this.downloadPdf = this.downloadPdf.bind(this);
  }

  generateAccordAndStore = () => {
    let currProspect = sessionStorage.getItem("currProspect")
      ? JSON.parse(sessionStorage.getItem("currProspect"))
      : undefined;
    let quoteData = sessionStorage.getItem("quoteData")
      ? JSON.parse(sessionStorage.getItem("quoteData"))
      : undefined;

    if (currProspect && quoteData) {
      const email = currProspect.companyProfile.emailId.value;
      const folder1 = `${email}/${quoteData.uuid}_${quoteData.date}`;

      console.log("inside generateAccordAndStore on page three");
      this.setState({ loadingPEO: true });
      // axios
      //   .post(awsUrl2 + "/api/generateProposal", JSON.stringify(etQuoteDetails))
      //   .then((res) => {
      let lossRunData = {
        key: `${folder1}/proposalDoc/proposal.pdf`,
        fileType: "pdf",
      };

      axios
        .post(awsUrl + "/api/getLossRunForm", JSON.stringify(lossRunData))
        .then(async (response) => {
          let fileURL;
          if (response.data) {
            const { signedURL } = response.data;
            let res = { data: signedURL };
            await axios
              .get(signedURL, { responseType: "blob" })
              .then((res) => {
                const newBlob = new Blob([res.data], {
                  type: "application/pdf",
                });
                fileURL = URL.createObjectURL(newBlob);
                this.setState({ fileURL, loadingPEO: false });
              })
              .catch((err) => {
                console.log("error: ", err);
                this.setState({ loadingPEO: false });
              });
          } else {
            console.log("No data in response from getLossRunForm");
            this.setState({ loadingPEO: false });
          }
        })
        .catch((err) => {
          console.log("error: ", err);
          showErrAlert("prop gneration is not available due to error");
          this.setState({ loadingPEO: false });
        });
      // })
      // .catch((err) => {
      //   console.log("Err", err);
      //   showErrAlert("View Quote is not available due to error.");
      //   this.setState({ loadingPEO: false });
      // });
    }
  };

  componentDidMount() {
    $("html, body").animate({ scrollTop: 0 }, 250);
    var formStage = sessionStorage.getItem("formStage");
    if (formStage === "three") {
      console.log("inside comp did mount");
      this.generateAccordAndStore();
    }
  }

  componentWillMount() {
    //this.downloadPdf();
  }

  startFresh() {
    sessionStorage.clear();
    window.location.reload();
  }

  unColor() {
    $("#mailAdd").css("border-color", "#ababab");
    $("#mailAdd").focus();
  }

  downloadPdf() {
    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    let teap = sessionStorage.getItem("teap");

    let emodBodyData = {};

    if (currProspect) {
      let emodStatesData = currProspect.emodStatesData;
      for (let state in emodStatesData) {
        if (emodStatesData[state].response && emodStatesData[state].rate1) {
          emodBodyData[state] = {
            rate: emodStatesData[state].rate1.value,
          };
        }
      }

      let body = {
        companyName: currProspect.companyProfile.companyName.value,
        teap,
        primaryContact: currProspect.companyProfile.primaryContact.value,
        emodBodyData,
      };

      axios
        .post(awsUrl2 + "/api/generateBindForm/", body)
        .then((res) => {
          this.setState({ showUploadProgress: false });
          const fileName = "Application.pdf";
          console.log("data: ", res.data);
          let pdf = res.data.data;
          let file = blobPdfFromBase64String(pdf);
          var fileURL = URL.createObjectURL(file);
          let url = this.state.url;
          url.push(fileURL);
          this.setState({ url });
        })
        .catch((err) => {
          this.setState({ showUploadProgress: false });
          console.log("Error:", err);
        });
    }
  }

  downloadPdfOld() {
    if (
      $("#mailAdd").val() === "Select One" ||
      $("#mailAdd").css("border-left-color") === "rgb(255, 0, 0)"
    ) {
      $("#mailAdd").css("border-color", "red");
      $("#mailAdd")
        .siblings(".errMsg")
        .html(" is required");
      $("#mailAdd").focus();
    } else {
      if (
        sessionStorage.getItem("require") !== undefined &&
        sessionStorage.getItem("require") !== null &&
        sessionStorage.getItem("currProspect") !== null &&
        sessionStorage.getItem("currProspect") !== undefined
      ) {
        let email = JSON.parse(sessionStorage.getItem("currProspect"))
          .emailAddress;
        let require = sessionStorage.getItem("require");
        let timestamp = require.split("@")[0];
        let carrierObj = {};
        let stateList = [];
        let etQuoteDetails = {};
        let mail_address = $("#mailAdd").val();

        require.split("!!").map((val1) => {
          let carrierName =
            "carrier_" +
            val1
              .split("+")[0]
              .split("_")
              .reverse()[0];
          let stateName = val1.split("_").reverse()[1];
          let address = val1.split("+").reverse()[0];
          if (!(carrierName in carrierObj)) {
            carrierObj[carrierName] = { state: [], address: [] };
          }
          // if(!())
          if (!carrierObj[carrierName].state.includes(stateName)) {
            carrierObj[carrierName].state.push(stateName);
          }
          if (!carrierObj[carrierName].address.includes(address)) {
            carrierObj[carrierName].address.push(address);
          }
        });

        window.scrollTo(0, 0);
        console.log("++data acord", etQuoteDetails);

        Auth.currentSession().then((res) => {
          const USER_TOKEN = res.idToken.jwtToken;
          const header = { headers: { Authorization: "Bearer " + USER_TOKEN } };
          for (let carrier in carrierObj) {
            this.setState({ showUploadProgress: true });
            etQuoteDetails[carrier] = {
              email: email,
              timestamp: timestamp,
              carrier: carrier,
              mail_address: mail_address,
              state: carrierObj[carrier].state,
              address: carrierObj[carrier].address,
            };

            axios
              .post(awsUrl2 + "/api/generateBindForm/")
              .then((res) => {
                this.setState({ showUploadProgress: false });
                const fileName = "Application.pdf";
                console.log("data: ", res.data);
                let pdf = res.data.data;
                let file = blobPdfFromBase64String(pdf);
                var fileURL = URL.createObjectURL(file);
                let url = this.state.url;
                url.push(fileURL);
                this.setState({ url });
              })
              .catch((err) => {
                this.setState({ showUploadProgress: false });
                console.log("Error:", err);
              });
          }
        });
      }
    }
  }

  render() {
    let compName = "";

    let { tAndC, submitPressed, fileURL, loadingPEO } = this.state;
    try {
      let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
      compName = currProspect.companyName;
    } catch (err) {
      console.log(err);
    }
    let userEnteredAddress = [];
    if (
      sessionStorage.getItem("require") !== undefined &&
      sessionStorage.getItem("require") !== null
    ) {
      let i = 0;
      let require = sessionStorage.getItem("require").split("!!");

      let address;
      for (let add of require) {
        let addArray = add.split("+")[1].split("_");
        let addArrayRefined = [];
        addArray.forEach((e) => {
          if (e !== "None") {
            addArrayRefined.push(e);
          }
        });
        address = addArrayRefined.join(", ");
        userEnteredAddress.push(
          <option
            key={i}
            value={add
              .split("+")[1]
              .split("_")
              .join(",  ")}
          >
            {address}
          </option>
        );
        i++;
      }
      userEnteredAddress.push(
        <option key={i} value="o">
          Other
        </option>
      );
    }

    return (
      <div id="payBind" className="container-fluid per98">
        <NewProgress />
        <div className="row no-gutters formTitle row-formTitle">
          <h3 className="">
            <b>Here's your proposal</b>
          </h3>
          <span className="formTitle compName">{compName}</span>
        </div>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              this.setState({ loadingPEO: true }, () => {
                document
                  .getElementById("iframePEO")
                  .contentWindow.location.reload();
              });
              this.setState({ loadingPEO: false });
            }}
            disabled={!fileURL || loadingPEO}
          >
            refresh
          </button>
        </div>
        <div style={{ width: "100%", height: "645px" }}>
          {fileURL || !loadingPEO ? (
            <iframe
              id={`iframePEO`}
              src={fileURL}
              style={{ border: "none", width: "100%", height: "645px" }}
            ></iframe>
          ) : (
            <div className="d-flex align-item-center justify-content-center">
              LOADING...
            </div>
          )}
        </div>

        {/* <div id="paymentQuote">
          <form>
            {this.state.url.length >= 1 ? (
              this.state.url.map((url) => (
                <div>
                  <div className="row row-acord-preview-heading">
                    <div className="col-sm-5 text-center col-acord-download">
                      <p className="acord">
                        {"  "}
                        <a
                          id="accordLink"
                          className="linkLike"
                          href={url}
                          target="_blank"
                        >
                          {" "}
                          <img
                            src={require("../../../images/pdf2.png")}
                            alt=""
                          />
                          Download Acord
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="row no-gutters row-acord-preview">
                    <iframe src={url} frameborder="0"></iframe>
                  </div>
                  <div
                    className="d-flex justify-content-left flex-column"
                    style={{ fontSize: "1.3rem" }}
                  >
                    <p style={{ marginBottom: "1.5rem" }}>
                      <b>
                        Thank you for your business. Our account executive will
                        contact you within 24 hours to finalize terms and bind
                        your policy.
                      </b>
                    </p>
                    <a
                      className="align-self-center"
                      onClick={this.startFresh}
                      style={{ cursor: "pointer", color: "#0973d5" }}
                    >
                      Start New Quote
                    </a>
                  </div>
                </div>
              ))
            ) : (
              <div className="row no-gutters pdf-upload-placeholder">
                <h6>Please wait while loading the file</h6>
                <img
                  className="pdf-loader-gif"
                  src={require("../../../images/small_loader.gif")}
                  alt=""
                />
              </div>
            )}
            <nav aria-label="..."></nav>
          </form>
        </div> */}
      </div>
    );
  }
}

export default FormPayment;
