import React, { Component } from "react";
import { Link } from "react-router-dom";

class ConfirmQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { loadingData } = this.props;
    return (
      <div className="confirm-quote d-flex align-items-center justify-content-center">
        <Link to="/Quote">
          <button className="btn" disabled={loadingData}>
            Confirm Your Quote
          </button>
        </Link>
      </div>
    );
  }
}

export default ConfirmQuote;
